import React from "react";
import Above from "../../components/global/about/AboutAbove";
import Mission from "../../components/global/about/Mission";
import Story from "../../components/global/about/Story";
import Locations from "../../components/global/about/Locations";
import Team from "../../components/global/about/Team";
import Board from "../../components/global/about/Board";
import Culture from "../../components/global/about/Culture";
import {LocationProvider} from "@reach/router";

const HomePagePreview = props => {
  const { entry } = props;
  const data = entry?.getIn(["data"])?.toJS();
  if (data) {
    return (
        <LocationProvider>
            <Above {...data?.aboutBanner} />
            <Mission {...data?.aboutMission} />
            <Story {...data?.aboutStory} />
            <Locations {...data?.aboutLocation} />
            <Team {...data?.aboutPeople} />
            <Board {...data?.aboutBoard} />
            <Culture {...data?.aboutCulture} />
        </LocationProvider>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default HomePagePreview;
